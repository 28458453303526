import React, { useState, useEffect } from 'react';
import './App.css';

import { Amplify } from 'aws-amplify';
import { withAuthenticator, Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import config from './amplifyconfiguration.json';
Amplify.configure(config);

export default function App() {
  const [data1, setData1] = useState(null);
  const [data2, setData2] = useState(null);

  const pressRightButton = async () => {
    const response = await fetch('https://api-garage.severinfamily.com/pressbutton?side=right');
    const json = await response.json();
    setData1(json);
  };

  const pressLeftButton = async () => { 
    const response = await fetch('https://api-garage.severinfamily.com/pressbutton?side=left');
    const json = await response.json();
    setData2(json);
  };
  
  useEffect(() => {
    if (data1) {
      const timeout = setTimeout(() => {
        setData1(null);
      }, 5000);
      return () => clearTimeout(timeout); 
    }
  }, [data1]);
  
  useEffect(() => {
    if (data2) {
      const timeout = setTimeout(() => {
        setData2(null);
      }, 5000);
      return () => clearTimeout(timeout); 
    }
  }, [data2]);

  return (
    <Authenticator
      hideSignUp={true}
    >
      {({ signOut, user }) => {
        return (
         
          <div className="cards">
            <div className="card">
            <h3>Door A</h3>
            <button className="actionbutton" onClick={pressRightButton}/>
            {data1 && <pre>{JSON.stringify(data1, null, 2)}</pre>}
            {!data1 && <div className="empty-div"></div>} 
          </div>
          <div className="card">
            <h3>Door B</h3>
            <button className="actionbutton" onClick={pressLeftButton}/>
            {data2 && <pre>{JSON.stringify(data2, null, 2)}</pre>}
            {!data2 && <div className="empty-div"></div>} 
          </div>
          <div className="card2">
            <h3>Video</h3>
           <button type="button" 
                  onClick={(e) => 
                    {e.preventDefault(); window.location.href='https://www.youtube.com/redirect?q=www.amazon.com/primevideo';}}>
              Prime Video</button>
           <button type="button" 
                  onClick={(e) => 
                    {e.preventDefault(); window.location.href='https://www.youtube.com/redirect?q=tv.apple.com';}}>
              Apple TV</button>
          </div>
        
          <div className="footer"><button onClick={signOut}>Log off</button></div>
        </div>
        );
      }}
    </Authenticator>
    );
}

